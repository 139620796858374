<template>
	<div>
	  	<div class="modal-header">
	    	<h3 class="modal-title"> {{ account.email }} </h3>
	  	</div>
	  	<div class="modal-body">
	  		<p v-for="log in logs">
	  			<ul class="list-group list-group-horizontal" >
				  <li class="list-group-item">{{ log.source }}</li>
				  <li class="list-group-item">{{ log.message }}</li>
				</ul>
	  		</p>
	  	</div>
	    <div class="modal-footer">
	        <button type="button" class="btn btn-primary" @click="$emit('close')"> Закрыть </button>
	    </div>
	</div>
</template>

<script>
	
	export default {

		name: 'ModalLogsAccount',

		props: ['logs', 'account'],

		data: () => { return {} },

		methods: {}

	}

</script>

<style scoped>
	
	.modal-header {
	    padding: 1rem 1.5rem;
	}

	.modal-body {
	    padding: 1rem 1.5rem;
	}

	.modal-footer {
	    padding: 1rem 1.5rem;
	}

</style>