<template>
	<form @submit="sendForm">
      	<div class="modal-header">
        	<h3 class="modal-title"> {{ account.email }} </h3>
      	</div>
      	<div class="modal-body">
      		<div class="mb-3">
      			<label class="form-label"> Подпись </label>
      			<input type="text" placeholder="Введите подпись..." required="required" v-model="name" class="form-control">
      		</div>
      		<div class="mb-3">
      			<label class="form-label"> E-mail </label>
      			<input type="text" placeholder="Введите E-mail адрес..." required="required" v-model="email" class="form-control">
      		</div>
      		<div class="mb-3">
      			<label class="form-label"> Пароль </label>
      			<input type="text" placeholder="Введите пароль..." required="required" v-model="password" class="form-control">
      		</div>
      		<div class="mb-3 form-check">
			    <input type="checkbox" class="form-check-input" v-model="is_disabled" id="exampleCheck1">
			    <label class="form-check-label" for="exampleCheck1"> Заблокирован </label>
			</div>
      	</div>
	    <div class="modal-footer">
	    	<button type="submit" class="btn btn-primary" ref="submit">
	        	<span class="btn__text"> Сохранить </span>
	        	<span class="btn__load spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
	        </button>
	        <button type="button" class="btn btn-secondary" @click="$emit('close')"> Закрыть </button>
	    </div>
	</form>
</template>

<script>
	
	export default {

		name: 'ModalAddAccounts',

		props: ['account'],

		data: () => {

      		return {

      			name: null,
      			
      			email: null,
      			
      			password: null,

      			is_disabled: 0,

      		}

    	},

		methods: {

			async sendForm (e) {

          		e.preventDefault();

	    		this.$refs.submit.classList.add("btn--load")

	    		await this.$store.dispatch('editAccounts', { 
	    			id: this.account.id, 
	    			name: this.name, 
	    			email: this.email, 
	    			password: this.password, 
	    			is_disabled: this.is_disabled
	    		})

    			await this.$store.dispatch('accounts')
	    		
	    		this.$emit('close')

	    		this.$refs.submit.classList.remove("btn--load")

          		return false;

			}

		},

		mounted () {

			this.name = this.account.name
			this.email = this.account.email
			this.password = this.account.password
			this.is_disabled = this.account.is_disabled

		},

	}

</script>


<style scoped>
	
	.modal-header {
	    padding: 1rem 1.5rem;
	}

	.modal-body {
	    padding: 1rem 1.5rem;
	}

	.modal-footer {
	    padding: 1rem 1.5rem;
	}

</style>