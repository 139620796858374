<template>

	<div class="card" :class="{ 'card--right' : isCurrent }">
	    
	    <div class="card-header"> 

	    	<div class="d-flex justify-content-between align-items-center">
	    		
	    		<strong> {{ item.mail.subject }} </strong>

	    		<button class="btn btn-secondary btn-sm" @click="replyTo"> <i class="bi bi-reply-fill"></i> </button>

	    	</div>

	    </div>
    	
	    <div class="card-body" v-html="item.mail.body"> </div>

	    <div class="card-footer d-flex justify-content-between align-items-center"> 

    		<span class="card-subtitle text-muted"> {{ moment(item.mail.date).format('DD MMMM YYYY HH:mm:ss') }} </span> 

    		<span class="card-subtitle text-muted"> {{ item.from_addres }} </span> 

	   	</div>
	  
	</div>

</template>

<script>

  import { mixins } from '../mixins'

  export default {

    name: 'User',

    mixins: [ mixins ],

    props: [ 'item' ],

    data: () => {

      return {  }

    },

    computed: {

    	isCurrent () {

    		return this.item.from_addres == this.$store.state.currentUser.email

    	},

    },
    
    methods: {

    	getSubjectReply () {

    		let subjectReply = '';

    		var myRe = /^(Re\[(\d+)\])|(Re)/gm;

    		var resReg = myRe.exec( this.item.mail.subject );

    		if (resReg == null) {

    			subjectReply = 'Re: ' + this.item.mail.subject

    		} else {

    			if (resReg[2]) {

    				let next = resReg[2] * 1 + 1;

    				subjectReply = this.item.mail.subject.replace( resReg[0] + ':', 'Re[' + next + ']:' )

    			} else {

    				subjectReply = this.item.mail.subject.replace( resReg[0] + ':', "Re[2]:" )

    			}

    		}

    		return subjectReply

    	},

    	replyTo () {

    		this.$store.state.currentSubject = this.getSubjectReply()

    		this.$store.state.currentMessage = null

    		this.$store.state.currentReply = this.item

    	}

    },

  }

</script>

<style scoped>
	
	.text-muted {
		font-size: 14px;
	}
	.card {
	    margin-bottom: 10px;
	    transition: all .3s ease-out;
	    overflow: hidden;
	    border-radius: 3px;
	    width: 80%;
		float: left;
	    /*font-weight: 500;*/
	}

	.card-header {
	    padding: 8px 13px;
	}
	.card-body {
	    padding: 15px 13px;
	    font-size: 15px;
	}
	.card-footer {
	    padding: 10px 13px;
    	padding-top: 12px;
	}

	.card--selected, .card--selected:hover {
	    border-color: #212529;
	}

	.card--right {
		float: right;
	}

	.btn-sm {
		padding: 2px 8px 4px 8px;
	}

</style>