<template>
	<form @submit="sendForm">
      	<div class="modal-header">
        	<h3 class="modal-title"> Добавить исключение </h3>
      	</div>
      	<div class="modal-body">
      		<div class="mb-3">
      			<label class="form-label"> E-mail адрес </label>
      			<input type="email" required="required" class="form-control" v-model="email">
      			<div class="form-text"> Данныей E-mail адрес не будет отображаться в переписках </div>
      		</div>
      	</div>
	    <div class="modal-footer">
	        <button type="submit" class="btn btn-primary" ref="submit"> 
	        	<span class="btn__text"> Сохранить </span>  
	        	<span class="btn__load spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
	        </button>
	        <button type="button" class="btn btn-secondary" @click="$emit('close')"> Закрыть </button>
	    </div>
	</form>
</template>

<script>
	
	export default {

		name: 'ModalAddAccounts',

		props: ['text'],

		data: () => {

      		return {

      			email: null,

      		}

    	},

		methods: {

			async sendForm (e) {

          		e.preventDefault();

          		if (this.email == '' || this.email == null || this.email == ' ') { return }

	    		this.$refs.submit.classList.add("btn--load")

	    		await this.$store.dispatch('addIgnore', { email: this.email } )

    			await this.$store.dispatch('ignore')

	    		this.$emit('close')

	    		this.$refs.submit.classList.remove("btn--load")

          		return false;

			}

		}

	}

</script>


<style scoped>
	
	.modal-header {
	    padding: 1rem 1.5rem;
	}

	.modal-body {
	    padding: 1rem 1.5rem;
	}

	.modal-footer {
	    padding: 1rem 1.5rem;
	}

</style>