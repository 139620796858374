<template>
  
  <div class="card" :class="{ 'text-white bg-primary' : item.addres == currentClient }" @click="selectClient">
    
    <div class="card-body">
      
      <div class="card__container">
          
        <span class="card__name"> {{ item.addres }} </span>

        <span class="badge bg-danger" v-if="item.notViewed > 0"> {{ item.notViewed }} </span>

      </div>

    </div>
  
  </div>

</template>

<script>

  import { mixins } from '../mixins'

  export default {

    name: 'Client',

    mixins: [ mixins ],

    props: [ 'item' ],

    data: () => {

      return {  }

    },

    computed: {

      currentClient () {

        return this.$store.state.currentClient

      },

    },
    
    methods: {

      async selectClient () {

        this.$store.state.currentSubject = null
        this.$store.state.currentMessage = null
        this.$store.state.currentReply = null

        await this.$store.commit('SET_CURRENT_CLIENT', this.item.addres)

        await this.$store.dispatch('mails')

        this.$store.dispatch('addMark')

      },

    },

  }

</script>

<style scoped>
	
	.card {
    margin-bottom: 5px;
    cursor: pointer;
    transition: all .2s ease-out;
    overflow: hidden;
    border-radius: 2px;
	}

  .card:hover {
    border-color: rgb(13 110 253);
  }

  .card-body {
    padding: 8px 13px;
  }

  .card--selected, .card--selected:hover {
    border-color: #212529;
  }

  .bg-primary {
    border-color: rgb(13 110 253);
  }

  .card.bg-primary:hover {
    border-color: rgb(13 110 253);
  }



  .card__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card__name {
    font-size: 13px;
    font-weight: 600;
  }

</style>