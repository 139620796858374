<template>
  <div id="app">
    <!-- Шапка -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark bg-gradient">
      <div class="container-fluid">
        
        <router-link class="navbar-brand mb-0 h1" style="margin-right: 3rem" to='/'>MailParser</router-link>
        
        <button 
          class="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarNavAltMarkup" 
          aria-controls="navbarNavAltMarkup" 
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <router-link class="nav-link" to='/'> Мессенджер </router-link>
            <router-link class="nav-link" to='/accounts'> Аккаунты </router-link>
            <router-link class="nav-link" to='/lists'> Список рассылки </router-link>
            <router-link class="nav-link" to='/ignore'> Игнорировать входящие </router-link>
            <router-link class="nav-link" to='/templates'> Шаблоны сообщений </router-link>
          </div>
        </div>

        <span class="text-white mx-4" v-if="state != null"> {{ moment(state.date_last_use).format('DD MMMM YYYY HH:mm:ss') }} </span>

        <button class="btn btn-outline-light" type="submit" @click.stop="addParserAll($event)">
            <span class="btn__text"> Обновить все аккаунты </span>
            <span class="btn__load spinner-border spinner-border-sm"></span>
        </button>

      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
  
  export default { 

    name: 'App',

    async mounted () {

      await this.$store.dispatch('state')

    },

    computed: {

      state () {

        return this.$store.state.state

      },

    },

    methods: {

      async addParserAll (event) {

        event.preventDefault()

        let btn = event.currentTarget;
        
        btn.classList.add("btn--load")

        try {

          await this.$store.dispatch('addParserAll')

          await this.$store.dispatch('state')

        } catch (e) {

          console.log(e)

        }

        btn.classList.remove("btn--load")

      },

    }

  }

</script>