<template>
  
  <div class="chat">

    <div class="container-fluid">

      <div class="row">
        
        <div class="col-xs-12 col-sm-4 col-md-3"> <Users /> </div>

        <div class="col-xs-12 col-sm-4 col-md-3"> <Clients v-if="currentUser" /> </div>

        <div class="col-xs-12 col-sm-4 col-md-6"> <Mails v-if="currentClient" /> </div>

      </div>

    </div>

  </div>
  
</template>

<script>

  import Users from './Users.vue'

  import Clients from './Clients.vue'

  import Mails from './Mails.vue'

  export default {
    
    name: 'Messenger',
    
    components: { Users, Clients, Mails },

    async mounted () {

      await this.$store.dispatch('users')

      await this.$store.dispatch('templates')

    },

    computed: {

      currentClient () {

        return this.$store.state.currentClient

      },

      currentUser () {

        return this.$store.state.currentUser

      },
      

    }

  }

</script>

<style scoped>

  .chat {

    padding-top: 25px;

    padding-bottom: 25px;

  }

</style>