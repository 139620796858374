<template>
	<div class="lists">
		<div class="container-fluid">

			<button type="button" class="btn btn-primary mb-3" @click="openAddMailings"> Добавить рассылку </button>

			<div class="table-responsive">
				<table class="table table-hover align-middle">
					<thead>
						<tr>
							<th scope="col"> ID </th>
							<th scope="col"> Действия </th>
							<th scope="col"> Дата создания </th>
							<th scope="col"> Название </th>
							<th scope="col"> Всего </th>
							<th scope="col"> Выполнено </th>
							<th scope="col"> Конверсия </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="mailing in mailings" :key="mailing.id" class="clicked">
              				<th scope="row"> {{ mailing.id }}</th>
              				<td>
								<div class="d-grid gap-2 d-flex">
									
									<button class="btn btn-primary btn-sm" @click.stop="openUpdateList(mailing, $event)">
								    	<span class="btn__text"> <i class="bi bi-pencil"></i> </span>
    									<span class="btn__load spinner-border spinner-border-sm"></span>
									</button>

									<button class="btn btn-danger btn-sm" @click="deleteMailing(mailing.id, $event)">
									    <span class="btn__text"> <i class="bi bi-x-lg"></i> </span>
        								<span class="btn__load spinner-border spinner-border-sm"> </span>
									</button>

								</div>
							</td>
							<td> {{ moment(mailing.date).format('DD MMMM YYYY HH:mm:ss') }} </td>
							<td> {{ mailing.name }} </td>
							<td> {{ mailing.tCount }} </td>
							<td> {{ mailing.tComplete }} </td>
							<td> {{ mailing.tConvert }} </td>
							
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	
  	import ModalAddMailings from './modals/ModalAddMailings.vue'

  	import ModalUpdateMailings from './modals/ModalUpdateMailings.vue'


	import { mixins } from '../mixins'

	export default {

		name: 'Lists',

		mixins: [ mixins ],

		data: () => {

			return {}

		},

		async mounted () {

			await this.$store.dispatch('mailings')

		},

		computed: {

			mailings () {

				return this.$store.state.mailings

			},

		},

		methods: {

			async openUpdateList(mailing, event) {

    			this.$modal.show( ModalUpdateMailings, { mailing: mailing }, { draggable: false, adaptive: true, height: 'auto' } )
				
			},

			async deleteMailing (id, event) {

				event.preventDefault();

    			var isDelete = confirm("Удалить рассылку?");

    			if (isDelete) {

    				event.currentTarget.classList.add("btn--load")

    				await this.$store.dispatch('deleteMailing', {id: id})

    				await this.$store.dispatch('mailings')

    			}

			},

			openAddMailings () {

    			this.$modal.show( ModalAddMailings, { text: '' }, { draggable: false, adaptive: true, height: 'auto' } )

			}

		},


	}

</script>

<style scoped>

	.clicked {

		cursor: pointer;

	}

	.lists {

		padding-top: 25px;

		padding-bottom: 25px;

	}
	
</style>