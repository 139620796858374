export const mixins = {
  data () {
  },
  methods: {
    numberWithSpaces: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  	formatPhoneNumber: function (str, prefix) {
	  	let cleaned = ('' + str).replace(/\D/g, '')
	  	let match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/)
  	  	if (match) {
  	    	return prefix + ' (' + match[1] + ') ' + match[2] + '-' + match[3] + '-' + match[4]
  	  	}
  	 	return null;
  	},
    declOfNum: (number, titles) => {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
  }
}