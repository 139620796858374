<template>
	<div class="ignore">
	    <div class="container-fluid">

	    	<button type="button" class="btn btn-primary mb-3" @click="openAddIgnore"> Добавить исключение </button>

	    	<div class="table-responsive">
				<table class="table table-hover align-middle">
				  <thead>
				    <tr>
				    	<th scope="col"> ID </th>
						<th scope="col"> Действия </th>
				      	<th scope="col"> Email </th>
				    </tr>
				  </thead>
				  <tbody>
				    <tr v-for="item in ignore" :key="item.id">
				    	<th scope="row"> {{ item.id }} </th>
				    	<td>
							<div class="d-grid gap-2 d-flex">
								<button class="btn btn-danger btn-sm" @click.stop="deleteIgnore(item.id, $event)">
								    <span class="btn__text"> <i class="bi bi-x-lg"></i> </span>
    								<span class="btn__load spinner-border spinner-border-sm"></span>
								</button>
							</div>
						</td>
				      	<td> {{ item.email }} </td>
						
				    </tr>
				  </tbody>
				</table>
	    	</div>

		</div>
	</div>
</template>

<script>
	
  	import ModalAddIgnore from './modals/ModalAddIgnore.vue'

  	import { mixins } from '../mixins'

	export default {

		name: 'Accounts',

    	mixins: [ mixins ],

    	data: () => {

      		return {}

    	},

    	async mounted () {

	      	await this.$store.dispatch('ignore')

	    },

	    computed: {

      		ignore () {

	       	 	return this.$store.state.ignore

	      	},

	    },
	    
    	methods: {

    		async openAddIgnore () {

    			this.$modal.show( ModalAddIgnore, {}, { draggable: false, adaptive: true, height: 'auto' } )

    		},

    		async deleteIgnore (id, e) {

          		e.preventDefault();

    			var isDelete = confirm("Удалить E-mail?");

    			if (isDelete) {

    				console.log(id)

    				e.currentTarget.classList.add("btn--load")

    				await this.$store.dispatch('deleteIgnore', { id: id })

    				await this.$store.dispatch('ignore')

    			}

    		}

    	},

	}

</script>

<style scoped>

	.ignore {

	    padding-top: 25px;

	    padding-bottom: 25px;

	}
	
</style>