<template>
	<form @submit="sendForm">
      	<div class="modal-header">
        	<h3 class="modal-title"> Редактировать шаблон </h3>
      	</div>
      	<div class="modal-body">

      		<div class="mb-3">
      			<label class="form-label"> Название </label>
      			<input type="text" required="required" class="form-control" v-model="name">
      		</div>

      		<label class="form-label"> Тело </label>
          	<textarea class="form-control" rows="8" v-model="body"> </textarea>
      		<div class="form-text"> Шаблон можно будет использовать как быстрый ответ на сообщение </div>

      	</div>
	    <div class="modal-footer">
	        <button type="submit" class="btn btn-primary" ref="submit"> 
	        	<span class="btn__text"> Сохранить </span>  
	        	<span class="btn__load spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
	        </button>
	        <button type="button" class="btn btn-secondary" @click="$emit('close')"> Закрыть </button>
	    </div>
	</form>
</template>

<script>
	
	export default {

		name: 'ModalUpdateTemplate',

		props: ['template'],

		data: () => {

      		return {

      			name: null,

      			body: null,

      		}
      		
    	},

    	mounted () {

    		this.name = this.template.name

    		this.body = this.template.body

    	},

		methods: {

			async sendForm (e) {

          		e.preventDefault();

          		if (this.name == '' || this.name == null || this.name == ' ') { return }

          		if (this.body == '' || this.body == null || this.body == ' ') { return }

	    		this.$refs.submit.classList.add("btn--load")

	    		let response = await this.$store.dispatch( 'updateTemplate', { id: this.template.id, name: this.name, body: this.body } )

    			await this.$store.dispatch('templates')
	    		
	    		this.$emit('close')

	    		this.$refs.submit.classList.remove("btn--load")

          		return false;

			}

		}

	}

</script>


<style scoped>
	
	.modal-header {
	    padding: 1rem 1.5rem;
	}

	.modal-body {
	    padding: 1rem 1.5rem;
	}

	.modal-footer {
	    padding: 1rem 1.5rem;
	}

</style>