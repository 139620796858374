<template>
  
  <div class="card bg-gradient" :class="{ 'text-white bg-danger' : item.is_disabled, 'text-white bg-primary' : isSelected }" @click="selectUser">

    <div class="card-body">
      
      <div class="card__container">
          
          <div class="card__text">

            <span class="card__name"> <strong> {{ item.name }} </strong> </span>

            <small class="card__email"> {{ item.email }} </small>

          </div>

          <div class="card__action">

            <span class="badge bg-warning" v-if="item.notViewed > 0"> {{ item.notViewed }} </span>
            
            <button class="btn btn-secondary btn-sm" @click.stop="addParser($event)" v-if="!item.is_disabled">

              <span class="btn__text"> <i class="bi bi-arrow-clockwise"></i> </span>
            
              <span class="btn__load spinner-border spinner-border-sm"></span>

            </button>

          </div>
          

      </div>

    </div>
  
  </div>

</template>

<script>

  import { mixins } from '../mixins'

  export default {

    name: 'User',

    mixins: [ mixins ],

    props: [ 'item' ],

    data: () => {

      return {

      }

    },

    computed: {

      isSelected () {

        return ( this.$store.state.currentUser && this.item.id == this.$store.state.currentUser.id ) ? true : false

      }

    },
    
    methods: {

      async addParser (event) {

        event.preventDefault()

        let btn = event.currentTarget;

        btn.classList.add("btn--load")

        try {

          await this.$store.dispatch('addParser', { id: this.item.id })

        } catch (e) {

          console.log(e)

        }

        btn.classList.remove("btn--load")

      },

      async selectUser () {

        // await store.commit('SET_CLIENTS', null)

        await this.$store.commit('SET_MAILS', null)

        await this.$store.commit('SET_CURRENT_CLIENT', null)

        this.$store.commit( 'SET_CURRENT_USER', this.item )

        await this.$store.dispatch( 'clients', { id: this.item.id } )

      },

    },

  }

</script>

<style scoped>
	
	.card {
	    margin-bottom: 5px;
	    cursor: pointer;
	    transition: all .2s ease-out;
	    overflow: hidden;
	    border-radius: 2px;
	    font-weight: 500;
	}

  .card:hover {
    border-color: rgb(13 110 253);
  }

  .card-body {
    padding: 8px 13px;
  }

  .bg-primary {
    border-color: rgb(13 110 253);
  }

  .card.bg-primary:hover {
    border-color: rgb(13 110 253);
  }

  .card.disabled {
    opacity: .5;
    pointer-events: none;
  }


  	.card__container {
    	display: flex;
    	flex-direction: row;
   	 	align-items: center;
    	justify-content: space-between;
  	}

  	.card__text {
    	display: flex;
    	flex-direction: column;
    	padding-right: 20px;
  	}

  	.card__action {
    	display: flex;
    	flex-direction: row;
    	align-items: center;
    	justify-content: space-between;
  	}

  	.card__name {
    	font-size: 14px;
    	line-height: 1.4;
  	}

  	.card__email {
    	opacity: .65;
    	font-size: 12px;
    	line-height: 1.4;
    	word-break: break-all;
  	}

  	.card__action .btn  {
    	margin-left: 10px;
  	}

  	.bg-danger {
	    background-color: #dc3545!important;
	}

	.bg-primary {
	    background-color: #0d6efd!important;
	}

</style>