
import Messenger from './components/Messenger.vue';

import Accounts from './components/Accounts.vue';

import Lists from './components/Lists.vue';

import Ignore from './components/Ignore.vue';

import Templates from './components/Templates.vue';



const routes = [

    { path: '/', component: Messenger },

    { path: '/accounts', component: Accounts },

    { path: '/templates', component: Templates },

    { path: '/lists', component: Lists },

    { path: '/ignore', component: Ignore },

];

export default routes;