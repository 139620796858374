<template>

  <div class="bg-light panel" :class="{ 'panel--load' : isLoad }">

    <div class="spiner"> <div class="spinner-border" role="status"> <span class="visually-hidden"> </span> </div> </div>

    <div class="panel__container" ref="panel">
        
      <Mail v-for="chain in chains" :key="chain.id" v-bind:item="chain" />

    </div>

    <div class="panel__footer">
        
        <SendForm />

    </div>

  </div>

</template>

<script>
  
  import Mail from './Mail.vue'

  import SendForm from './SendForm.vue'

  import { mixins } from '../mixins'

  export default {

    name: 'Mails',

    mixins: [ mixins ],

    components: { Mail, SendForm },
    
    data: () => {

      return {}

    },

    watch: {

      chains () {

        setTimeout(() => { this.$refs.panel.scrollTop = this.$refs.panel.scrollHeight }, 0);

      },

    },

    computed: {

      chains () {

        return this.lodash.sortBy(this.$store.state.mails, 'mail.date')

      },

      isLoad () {

        return this.$store.state.isLoadMails

      },

    },

  }

</script>

<style scoped>
  
  .panel__container {
    height: calc(100% - 255px);
    overflow-y: auto;
    padding: 1rem
  }

  .panel__footer {
    border-top: 1px solid #dfdfdf;
    height: 255px;
    padding: 1rem
  }

</style>