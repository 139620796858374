<template>

  <div class="bg-light p-3 panel" :class="{ 'panel--load' : isLoad }">

    <div class="spiner"> <div class="spinner-border" role="status"> <span class="visually-hidden"> </span> </div> </div>

    <Client v-for="client in clients" :key="client.from_addres" v-bind:item="client"/>

  </div>

</template>

<script>
  
  import Client from './Client.vue'

  import { mixins } from '../mixins'

  export default {

    name: 'Clients',

    mixins: [ mixins ],

    components: { Client },

    data: () => {

      return {  }

    },

    methods: {
    
    },

    computed: {

      clients () {

        return this.$store.state.clients

      },

      isLoad () {

        return this.$store.state.isLoadClients

      },

    },

  }

</script>