<template>

	<form @submit="sendMessage">
        	
        <div class="mb-2">

        	<input class="form-control" type="text" v-model="currentSubject" placeholder="Тема">

        </div>

        <div class="mb-3">

	        <!-- <quill-editor
				    v-model="currentMessage"
				    :options="editorOption"
			/>-->

          	<textarea class="form-control" rows="4" v-model="currentMessage" placeholder="Написать сообщение..."> </textarea>
          
        </div>

        <div class="d-flex align-items-center">
        	
        	<button type="submit" class="btn btn-primary" ref="submit"> 
        
	        	<span class="btn__text"> Отправить </span>  

	        	<span class="btn__load spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

	        </button>

	        <div class="d-flex px-3" >

	        	<a href="#" v-for="template in templates" class="px-2 link-primary" @click="setTemplateMessage(template)"> {{ template.name }} </a>
	        
	        </div>

        </div>

    </form>

</template>

<script>

  	import { mixins } from '../mixins'

  	export default {

    	name: 'SendForm',

		mixins: [ mixins ],

	    data: () => {

	      return {

	      	editorOption: {
	          // Some Quill options...
	        }
        
	      }

	    },

	    computed: {

	    	currentSubject: {

				get () { return this.$store.state.currentSubject },

				set (val) { this.$store.state.currentSubject = val }

	    	},

			currentMessage: {

				get () { return this.$store.state.currentMessage },

				set (val) { this.$store.state.currentMessage = val }

			},

	      	currentClient () {

	        	return this.$store.state.currentClient

	      	},

	      	templates () {

	      		return this.$store.state.templates

	      	}

	    },

	    methods: {

	    	setTemplateMessage(template) {

	    		this.$store.state.currentMessage = template.body

	    	},

	    	async sendMessage (e) {

          		e.preventDefault();
          
	    		if (this.currentSubject == '' || this.currentSubject == null || this.currentSubject == ' ') { return }

	    		if (this.currentMessage == '' || this.currentMessage == null || this.currentMessage == ' ') { return }

	    		this.$refs.submit.classList.add("btn--load")

	    		let response = await this.$store.dispatch('sendMessage')

      			if (response.status) {

      				this.$store.state.mails.push(response.chain);

      			}

	    		this.$refs.submit.classList.remove("btn--load")

          		return false;

	    	}

	    }

  	}

</script>