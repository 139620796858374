<template>

	<form @submit="sendForm">

      	<div class="modal-header">
        	
        	<h3 class="modal-title"> Редактировать рассылку </h3>
      	
      	</div>

      	<div class="modal-body">
      		
      		<div class="mb-3">

      			<label class="form-label">Название рассылки</label>

      			<input type="text" class="form-control" v-model="name" placeholder="Введите название..." required>
      			
      		</div>

      		<div class="mb-3">

      			<label class="form-label">Тема письма</label>

      			<input type="text" class="form-control" v-model="subject" required>
      			
      		</div>

      		<div class="mb-3">

      			<label class="form-label">Содержание письма</label>

          		<textarea class="form-control" rows="8" v-model="body" required> </textarea>

          		<div class="form-text">

          			Для вставки вариативных значений используйте следующую конструкцию: 
          			
          			<br />
          			
          			<code> {Вариант1|Вариант2|Вариант3 ...} </code> 
					
					<br />
          			
          			Чтобы использовать в теле | теме письма E-mail или Имя отправителя, необходимо использовать следующие теги:
          			
          			<code> #email# </code> или <code> #name# </code> соответственно

          		</div>

      		</div>


      	</div>

	    <div class="modal-footer">

	        <button type="submit" class="btn btn-primary" ref="submit"> 

	        	<span class="btn__text"> Сохранить </span>
	        	
	        	<span class="btn__load spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

	        </button>

	        <button type="button" class="btn btn-secondary" @click="$emit('close')"> Закрыть </button>

	    </div>

	</form>

</template>

<script>
	
	export default {

		name: 'ModalAddAccounts',

		props: ['mailing'],

		data: () => {

      		return {

      			name: null,

      			subject: null,

      			body: null,

      		}

    	},

    	mounted () {

    		this.name = this.mailing.name

    		this.subject = this.mailing.subject

    		this.body = this.mailing.body

    	},

		methods: {

			async sendForm (e) {

          		e.preventDefault();

	    		this.$refs.submit.classList.add("btn--load")

	    		let response = await this.$store.dispatch('updateMailing', { id: this.mailing.id, name: this.name, subject: this.subject, body: this.body } )

	    		console.log(response)

				await this.$store.dispatch('mailings')

    			this.$emit('close')

    			this.$refs.submit.classList.remove("btn--load")

          		return false;

			}

		}

	}

</script>

<style scoped>
	
	.modal-header {
	    padding: 1rem 1.5rem;
	}

	.modal-body {
	    padding: 1rem 1.5rem;
	}

	.modal-footer {
	    padding: 1rem 1.5rem;
	}

</style>