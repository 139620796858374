import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './routes';
import store from './store'
import moment from 'moment'
import VueTheMask from 'vue-the-mask'
import VModal from 'vue-js-modal'
import lodash from 'lodash';  
import VueQuillEditor from 'vue-quill-editor'


import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme


Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(VueRouter)
Vue.use(VModal)
Vue.use(VueTheMask)

const router = new VueRouter({ mode: 'history', routes });

import './assets/css/main.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

Vue.prototype.moment = moment
Vue.prototype.lodash = lodash

moment.locale('ru')

Vue.config.productionTip = false


new Vue({

	router,

  	store: store,
  	
  	render: h => h(App),

}).$mount('#app')
