<template>
  
  <div class="bg-light p-3 panel" :class="{ 'panel--load' : isLoad }">

    <div class="spiner"> <div class="spinner-border" role="status"> <span class="visually-hidden"> </span> </div> </div>

    <User v-for="user in users" :key="user.id" v-bind:item="user"/>

  </div>

</template>

<script>

  import User from './User.vue'

  import { mixins } from '../mixins'

  export default {

    name: 'Users',

    mixins: [ mixins ],

    components: { User },

    data: () => {

      return {}

    },

    computed: {

      users () {

        return this.$store.state.users

      },

      isLoad () {

        return this.$store.state.isLoadUsers

      },

    },

  }

</script>