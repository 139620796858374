<template>
	<form @submit="sendForm">
      	<div class="modal-header">
        	<h3 class="modal-title"> Добавить аккаунты </h3>
      	</div>
      	<div class="modal-body">
      		<label class="form-label"> Список аккаунтов </label>
          	<textarea class="form-control" rows="8" v-model="accounts"> </textarea>
          	<div id="emailHelp" class="form-text"> 
          		Укажите несколько аккаунтов, каждый с новой строки в формате: 
          		<code> email#password#name </code> 
          	</div>
      	</div>
	    <div class="modal-footer">
	        <button type="submit" class="btn btn-primary" ref="submit"> 
	        	<span class="btn__text"> Сохранить </span>  
	        	<span class="btn__load spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
	        </button>
	        <button type="button" class="btn btn-secondary" @click="$emit('close')"> Закрыть </button>
	    </div>
	</form>
</template>

<script>
	
	export default {

		name: 'ModalAddAccounts',

		props: ['text'],

		data: () => {

      		return {

      			accounts: null,

      		}

    	},

		methods: {

			async sendForm (e) {

          		e.preventDefault();

          		if (this.accounts == '' || this.accounts == null || this.accounts == ' ') { return }

	    		this.$refs.submit.classList.add("btn--load")

	    		let accountsArray = this.accounts.split("\n")

	    		let response = await this.$store.dispatch('addAccounts', { accounts: accountsArray } )

	    		if (response.status) {

    				await this.$store.dispatch('accounts')
	    			
	    			this.$emit('close')

	    		}

	    		this.$refs.submit.classList.remove("btn--load")

          		return false;

			}

		}

	}

</script>


<style scoped>
	
	.modal-header {
	    padding: 1rem 1.5rem;
	}

	.modal-body {
	    padding: 1rem 1.5rem;
	}

	.modal-footer {
	    padding: 1rem 1.5rem;
	}

</style>