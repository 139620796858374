<template>
	<div class="accounts">
	    <div class="container-fluid">

	    	<button type="button" class="btn btn-primary mb-3" @click="openAddAccounts"> 
	    		<i class="bi bi-upload" style="margin-right: 5px;"></i>
	    		Добавить аккаунты 
	    	</button>

	    	<div class="table-responsive">
				<table class="table table-hover align-middle">
				  <thead>
				    <tr>
				    	<th scope="col"> ID</th>
						<th scope="col"> Действия</th>
				      	<th scope="col"> Имя </th>
				      	<th scope="col"> E-mail </th>
				      	<th scope="col"> Заблокирован </th>
				      	<th scope="col"> Дата последней проверки </th>
				    </tr>
				  </thead>

				  <tbody>
				    <tr v-for="account in accounts" :key="account.id" :class="{ 'table-danger' : account.is_disabled }">
				    	<th scope="row">{{ account.id }}</th>
				    	<td>
							<div class="d-grid gap-1 d-flex align-items-center">
								<button class="btn btn-primary btn-sm" @click.stop="editAccount(account)">
								    <span class="btn__text"> <i class="bi bi-pencil"></i> </span>
    								<span class="btn__load spinner-border spinner-border-sm"></span>
								</button>
								<button class="btn btn-danger btn-sm" @click.stop="deleteAccount(account.id, $event)">
								    <span class="btn__text"> <i class="bi bi-x-lg"></i> </span>
    								<span class="btn__load spinner-border spinner-border-sm"></span>
								</button>
							</div>
						</td>
				      	<td> 
							<div class="d-grid gap-1 d-flex">
				      			<span class="mx-2"> {{ account.name }} </span>
				      			<button class="btn btn-danger btn-sm badge bg-danger" @click.stop="logsAccount(account, $event)" v-if="account.logCount > 0">
								    <span class="btn__text"> {{ account.logCount }} </span>
    								<span class="btn__load spinner-border spinner-border-sm"></span>
								</button>
							</div>
				      	</td>
				      	<td> {{ account.email }} </td>
				      	<td> {{ account.is_disabled ? 'Да' : 'нет' }} </td>
				      	<td> {{ moment(account.date_parse).format('YYYY-MM-DD HH:mm:ss') }} </td>
				    </tr>
				  </tbody>

				</table>
	    	</div>
		</div>
	</div>
</template>

<script>
	
  	import ModalAddAccounts from './modals/ModalAddAccounts.vue'

  	import ModalEditAccount from './modals/ModalEditAccount.vue'

  	import ModalLogsAccount from './modals/ModalLogsAccount.vue'


  	import { mixins } from '../mixins'

	export default {

		name: 'Accounts',

    	mixins: [ mixins ],

    	data: () => {

      		return {

      		}

    	},

    	async mounted () {

	      	await this.$store.dispatch('accounts')

	    },

	    computed: {

	    	accounts () {

	    		return this.$store.state.accounts

	    	}

	    },

    	methods: {

    		async openAddAccounts () {

    			this.$modal.show( ModalAddAccounts, {}, { draggable: false, adaptive: true, height: 'auto' } )

    		},

    		async editAccount (account) {

    			this.$modal.show ( ModalEditAccount, { account: account }, { draggable: false, adaptive: true, height: 'auto' } )

    		},

    		async logsAccount (account, e) {

          		e.preventDefault();

          		var btn = e.currentTarget;

          		btn.classList.add("btn--load")

          		let logs = await this.$store.dispatch('searchLog', { id: account.id })

          		btn.classList.remove("btn--load")

    			this.$modal.show ( ModalLogsAccount, { logs: logs, account: account }, { draggable: false, adaptive: true, height: 'auto' } )

    		},

    		async deleteAccount (id, e) {

          		e.preventDefault();

          		var btn = e.currentTarget;

    			var isDelete = confirm("Удалить аккаунт?");

    			if (isDelete) {

    				btn.classList.add("btn--load")

    				await this.$store.dispatch('deleteAccount', { id: id })

    				await this.$store.dispatch('accounts')

    			}

    		},

    	},

	}

</script>

<style scoped>

	.accounts {

	    padding-top: 25px;

	    padding-bottom: 25px;

	}
	
</style>