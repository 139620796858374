import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import nl2br from 'nl2br';

axios.defaults.baseURL = '/api';

Vue.use(Vuex)

export default new Vuex.Store({

  state: {

    isLoadUsers: false,

    isLoadClients: false,

    isLoadMails: false,

    isLoadMailings: false,

    isLoadIgnore: false,

    isLoadAccounts: false,


    
    users: null,

    clients: null,

    mails: null,

    mailings: null,

    accounts: null,

    ignore: null,

    state: null,

    templates: null,




    currentUser: null,

    currentClient: null,

    currentSubject: null,

    currentMessage: null,

    currentReply: null,

  },

  mutations: {



    SET_LOAD_USERS (state, params) {

      state.isLoadUsers = params

    },

    SET_LOAD_CLIENTS (state, params) {

      state.isLoadClients = params

    },

    SET_LOAD_MAILS (state, params) {

      state.isLoadMails = params

    },

    SET_LOAD_MAILINGS (state, params) {

      state.isLoadMailings = params

    },

    SET_LOAD_IGNORE (state, params) {

      state.isLoadIgnore = params

    },

    SET_LOAD_ACCOUNTS (state, params) {

      state.isLoadAccounts = params

    },




    SET_USERS (state, params) {

      state.users = params

    },

    SET_CLIENTS (state, params) {

      state.clients = params

    },

    SET_MAILS (state, params) {

      state.mails = params

    },

    SET_MAILINGS (state, params) {

      state.mailings = params

    },

    SET_IGNORE (state, params) {

      state.ignore = params

    },

    SET_STATE (state, params) {

      state.state = params

    },

    SET_ACCOUNTS (state, params) {

      state.accounts = params

    },

    SET_TEMPLATES (state, params) {

      state.templates = params

    },


    




    SET_CURRENT_USER (state, params) {

      state.currentUser = params

    },

    SET_CURRENT_CLIENT (state, params) {

      state.currentClient = params

    },


  },

  actions: {

    async state (store) {

        let { data } = await axios.get('/state')

        await store.commit('SET_STATE', data)

    },

  	async users (store) {

      	await store.commit('SET_LOAD_USERS', true)

  		  let { data } = await axios.get('/users')

      	await store.commit('SET_USERS', data)

      	await store.commit('SET_LOAD_USERS', false)

  	},

    async accounts (store) {
    
        await store.commit('SET_LOAD_ACCOUNTS', true)

        let { data } = await axios.get('/accounts')

        await store.commit('SET_ACCOUNTS', data)

        await store.commit('SET_LOAD_ACCOUNTS', false)

    },

    async clients (store, params) {

      	await store.commit('SET_LOAD_CLIENTS', true)

      	let { data } = await axios.post('/clients', { id: params.id })

      	await store.commit('SET_CLIENTS', data)

      	await store.commit('SET_LOAD_CLIENTS', false)

    },

    async mails (store) {

      	await store.commit('SET_LOAD_MAILS', true)

      	let { data } = await axios.post('/mails', { email: store.state.currentClient, user: store.state.currentUser.id })

      	await store.commit('SET_MAILS', data)

      	await store.commit('SET_LOAD_MAILS', false)

    },











    async templates (store) {

        let { data } = await axios.get('/templates')

        await store.commit('SET_TEMPLATES', data)

    },

    async addTemplate (store, params) {

        let { data } = await axios.post('/add-template', { name: params.name, body: params.body } )

        return data;

    },

    async deleteTemplate (store, params) {

        let { data } = await axios.post('/delete-template', { id: params.id })

        return data;

    },

    async updateTemplate (store, params) {

        let { data } = await axios.post('/update-template', { id: params.id, name: params.name, body: params.body } )

        return data;

    },



    


    async mailings (store) {

        await store.commit('SET_LOAD_MAILINGS', true)

        let { data } = await axios.get('/mailings')

        await store.commit('SET_MAILINGS', data)

        await store.commit('SET_LOAD_MAILINGS', false)

    },

    async addMailing (store, params) {

      	let { data } = await axios.post('/add-mailings', params, { headers: { 'Content-Type': 'multipart/form-data' } } )

      	return data;

    },

    async deleteMailing (store, params) {

      	let { data } = await axios.post('/delete-mailings', { id: params.id })

      	return data;

    },

    async updateMailing (store, params) {

        let { data } = await axios.post('/update-mailings', { id: params.id, name: params.name, body: params.body, subject: params.subject } )

        return data;

    },



    



    async ignore (store) {

        await store.commit('SET_LOAD_IGNORE', true)

        let { data } = await axios.get('/ignore')

        await store.commit('SET_IGNORE', data)

        await store.commit('SET_LOAD_IGNORE', false)

    },

    async addIgnore (store, params) {

        let { data } = await axios.post('/add-ignore', { email: params.email } )

        return data;

    },

    async deleteIgnore (store, params) {

        let { data } = await axios.post('/delete-ignore', { id: params.id })

        return data;

    },
    



    

    async addParser (store, params) {

        let { data } = await axios.post('/add-parser', { id: params.id })

        return data;

    },

    async addParserAll (store, params) {

        let { data } = await axios.post('/add-parser-all')

        return data;

    },

    async addMark (store, params) {

        let { data } = axios.post('/add-mark', { client: store.state.currentClient, user: store.state.currentUser.id })
        
        var notViewed = 0

        // Смотрим массив с клиентами пользователя
        for (var i = store.state.clients.length - 1; i >= 0; i--) {

          // Находим в списке клиента
          if (store.state.clients[i].addres == store.state.currentClient) {

            // Запоминаем сколько было
            notViewed = store.state.clients[i].notViewed

            // Скидываем счётчик непрочитанных
            store.state.clients[i].notViewed = 0

          }

        }

        // Смотрим массив со всеми пользователями
        for (var i = store.state.users.length - 1; i >= 0; i--) {

          // Находим в списке пользователя
          if (store.state.users[i].id == store.state.currentUser.id) {

            // уменьшаем счётчик сколько было у клиента не прочитанных
            store.state.users[i].notViewed = store.state.users[i].notViewed * 1 - notViewed

          }
          
        }

        return data;

    },

    async sendMessage (store, params) {

      // ответное письмо
    	let replyId = false

      // Сообщение
      let message = store.state.currentMessage

      // Если это перессылка
      if (store.state.currentReply !== null) {

          replyId = store.state.currentReply.mail.messageid

          message = nl2br(message) + '\
            <br />\
            <br />\
            <blockquote style="border-left:1px solid #0857A6; margin:10px; padding:0 0 0 10px;">\
              ' + moment(store.state.currentReply.mail.date).format('DD MMMM YYYY, HH:mm') + ' от &lt;' + store.state.currentReply.from_addres + '&gt;:\
              <br />\
              <br />\
              <div> ' + store.state.currentReply.mail.body + ' </div>\
            </blockquote>'

      }

    	// Отправляем сообщение
      let { data } = await axios.post('/send-mail', { 

      		// Кому отправить
        	email: store.state.currentClient,

        	// Кто отправляет
        	user: store.state.currentUser.id,

        	// Тема письма
        	subject: store.state.currentSubject,

        	// На какое письмо ответ
        	reply: replyId,

          // Текст письма
          message: message,

      })

    	// Очищаем после отправки
      store.state.currentSubject = null
		  store.state.currentMessage = null
		  store.state.currentReply = null

      return data;

    },

    async addAccounts (store, params) {

      	let { data } = await axios.post('/add-accounts', { accounts: params.accounts })

      	return data;

    },

    async editAccounts (store, params) {

        let { data } = await axios.post('/edit-accounts', params)

        return data;

    },

    async searchLog (store, params) {

        let { data } = await axios.post('/search-log', { id: params.id })

        return data;

    },

    async deleteAccount (store, params) {

      let { data } = await axios.post('/delete-account', { id: params.id })

      return data

    },

  },

})