<template>
	<div class="ignore">
	    <div class="container-fluid">

	    	<button type="button" class="btn btn-primary mb-3" @click="openAddTemplate"> Создать шаблон </button>

	    	<div class="table-responsive">
				<table class="table table-hover align-middle">
				  <thead>
				    <tr>
				    	<th scope="col"> ID </th>
						<th scope="col"> Действия </th>
				      	<th scope="col"> Название </th>
				      	<th scope="col"> Тело </th>
				    </tr>
				  </thead>
				  <tbody>
				    <tr v-for="item in templates" :key="item.id">
				    	<th scope="row"> {{ item.id }} </th>
				    	<td>
							<div class="d-grid gap-2 d-flex">
								
								<button class="btn btn-primary btn-sm" @click.stop="openUpdateTemplate(item, $event)">
								    <span class="btn__text"> <i class="bi bi-pencil"></i> </span>
    								<span class="btn__load spinner-border spinner-border-sm"></span>
								</button>

								<button class="btn btn-danger btn-sm" @click.stop="deleteTemplate(item.id, $event)">
								    <span class="btn__text"> <i class="bi bi-x-lg"></i> </span>
    								<span class="btn__load spinner-border spinner-border-sm"></span>
								</button>

							</div>
						</td>
				      	<td> {{ item.name }} </td>
				      	<td> {{ item.body }} </td>
				    
				    </tr>
				  </tbody>
				</table>
	    	</div>

		</div>
	</div>
</template>

<script>
	
  	import ModalAddTemplate from './modals/ModalAddTemplate.vue'

  	import ModalUpdateTemplate from './modals/ModalUpdateTemplate.vue'


  	import { mixins } from '../mixins'

	export default {

		name: 'Accounts',

    	mixins: [ mixins ],

    	data: () => {

      		return {}

    	},

    	async mounted () {

	      	await this.$store.dispatch('templates')

	    },

	    computed: {

      		templates () {

	       	 	return this.$store.state.templates

	      	},

	    },
	    
    	methods: {

    		async openUpdateTemplate (item) {

    			this.$modal.show( ModalUpdateTemplate, { template: item }, { draggable: false, adaptive: true, height: 'auto' } )

    		},

    		async openAddTemplate () {

    			this.$modal.show( ModalAddTemplate, {}, { draggable: false, adaptive: true, height: 'auto' } )

    		},

    		async deleteTemplate (id, e) {

          		e.preventDefault();

    			var isDelete = confirm("Удалить шаблон?");

    			if (isDelete) {

    				console.log(id)

    				e.currentTarget.classList.add("btn--load")

    				await this.$store.dispatch('deleteTemplate', { id: id })

    				await this.$store.dispatch('templates')

    			}

    		}

    	},

	}

</script>

<style scoped>

	.ignore {

	    padding-top: 25px;

	    padding-bottom: 25px;

	}
	
</style>